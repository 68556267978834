import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBContainer } from 'mdbreact';
import ServiceModal from './ServiceModal';
import './serviceModal.scss'

const ServiceList = (props) => {

    const data = useStaticQuery(graphql`
    {
        allServicesJson {
            edges {
              node {
                name
                ecin
                rdin
                rdout
                materials {
                  item
                  qty
                }
                extra {
                  item
                  qty
                }
              }
            }
          }
    }`);
    const [state, setState] = React.useState({ modal: false, node: null });
    function handleToggle(mat) {
        setState({ modal: !state.modal, node: mat })
        // console.log(state);
    }
    function handleServiceClick(mat) {
        //console.log(mat)
        handleToggle(mat);

    }
    const eventsList = data.allServicesJson.edges.map((edge, index) => {
        return (
            <tr key={index} >
                <td className="font-weight-bolder deep-orange-text">{edge.node.name}</td>
                <td><MDBBtn onClick={() => handleServiceClick(edge.node)}> Materials</MDBBtn></td>
                <td className="font-weight-bold deep-purple-text" >{edge.node.ecin}</td>
                <td className="font-weight-bold deep-purple-text">{edge.node.rdin}</td>
                <td className="font-weight-bold deep-purple-text">{edge.node.rdout}</td>

            </tr>
        )
    }
    );


    return (
        <MDBContainer>
            <MDBTable hover striped bordered>
                <MDBTableHead color="info-color" textWhite>
                    <tr>
                        <th>Service Name</th>
                        <th>Click to view</th>
                        <th>SCHEDULED BY EC IN TEMPLE (3 HOURS)</th>
                        <th>REQUESTED BY DEVOTEES IN TEMPLE (3 HOURS)</th>
                        <th>OUTSIDE TEMPLE (3 HOURS) ($50 EXTRA FOR WEEKEND)</th>

                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {eventsList}
                </MDBTableBody>
            </MDBTable>
            <ServiceModal state={state} toggle={handleToggle} />
        </MDBContainer>
    )
}

export default ServiceList;