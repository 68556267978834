import React from 'react';
//import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import Pageheader from '../Utils/pageheader';
import ServiceList from '../components/Service/ServiceList';

const ServicesPage = () => {
    return (
        <>
            <SEO title="Services" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">POOJA/PRIEST SERVICES</h3>
                <Pageheader />
                <p className=" text-justify" >Temple Executive Committee has made available the services listed in the tabulation below. Temple Executive Committee needs 30-days advance notice to process and approve your service. All services are handled on a first-come-first-served basis. Temple's own programs receive priority over requested services.</p>
                <h5 className=" font-weight-bold text-justify text-primary">Please submit your request - <a href="https://docs.google.com/forms/d/1-iH7BmgaaMnoM9lwyPj5sntR_A9Wph6n3nFl8Q1ywMY/viewform" target="_blank" rel="noopener noreferrer" className="font-weight-bold">Pooja Request Form</a></h5>
                <p className=" text-justify" >Religious Committee of the Temple Executive Committe will review & confirm the pooja/priest service request. Sorry, no confirmation is made over the phone.</p>

                <p className="text-justify text-danger">**PLEASE NOTE** : Temple Priest has the day off on Thursdays only and therefore, no private service for any location types(in-temple, outside temple or virtual) are accepted or approved for Thursdays(certain exceptions may be allowed)</p>
                <p className=" font-weight-bold text-justify text-primary">Please read : <a href="https://docs.google.com/document/d/1QNYscPVk_LrlPFUCWDXVICeEnBGXOf7kHWn9Azfb-z0/edit?usp=sharing" target="_blank" rel="noopener noreferrer" className="font-weight-bold">Priest and Temple Services Terms and Conditions. </a>
                    You <span className="red-text"> must agree</span> to Temple & Priest service terms & conditions before you request priest service. All charges are subject to change by the Temple Executive Committee </p>
                <Pageheader />
                
                <MDBRow>
                    <MDBCol>
                        <ServiceList />
                    </MDBCol>
                </MDBRow>

                <Pageheader />
                <div className="col-md-12 col-sm-12">
                    <p className="text-justify">
                        * Temple kitchen &amp; dining hall if required, must be reserved separately. Use facilities <a href="/forms" className="links"><strong>reservation form</strong></a> to reserve kitchen &amp; dinning hall. Prayer hall is available only for religious services. Sponsors must supply or buy all pooja materials. Temple would supply pooja materials in case they are unavailable, for a nominal donation to Temple.
                    </p>
                    <p className=" text-justify">
                        ** Make checks payable to "Hindu Temple Association of Eastern Iowa". <strong>Please donate to temple generously (donations are eligible for tax deduction).</strong>
                    </p>
                </div>
            </MDBContainer>
        </>
    );
};

export default ServicesPage;