import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';

class ServiceModal extends Component {
    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    handlePrintAction = (e) => {
        const content = document.getElementsByClassName('printarea');
        const pri = document.getElementById('ifmcontentstoprint').contentWindow;
        pri.document.open();
        pri.document.write(content[0].innerHTML + content[1].innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }
    render() {
        let extraList = null;
        if (this.props.state.node && this.props.state.node.extra) {
            extraList = this.props.state.node.extra.map((ext, index) => {
                return (
                    <tr key={index}>
                        <td>{ext.item} : </td> <td>{ext.qty}</td>
                    </tr>
                )
            })
        }
        const materialList = this.props.state.node && this.props.state.node.materials.map((mat, index) => {

            return (
                <tr key={index}>
                    <td>{mat.item} : </td> <td>{mat.qty}</td>
                </tr>
            )
        })
        return (
            <MDBContainer>
                <MDBModal isOpen={this.props.state.modal} toggle={() => this.props.toggle(this.props.state.node)} >
                    <MDBModalHeader toggle={() => this.props.toggle(this.props.state.node)} className="printarea">{this.props.state.node && this.props.state.node.name} Materials List</MDBModalHeader>
                    <MDBModalBody className="printarea" style={{ maxHeight: "400px", overflowY: "scroll" }} >
                        <MDBTable hover striped bordered>
                            <MDBTableHead color="primary-color" textWhite>
                                <tr>
                                    <th>Item</th>
                                    <th>Qty</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {materialList}
                            </MDBTableBody>
                        </MDBTable>
                        {(this.props.state.node && this.props.state.node.extra) ? <h4 class="text-secondary">Extra List</h4> : ""}
                        <MDBTable hover striped bordered>
                            {(this.props.state.node && this.props.state.node.extra) ? <MDBTableHead color="primary-color" textWhite>
                                <tr>
                                    <th>Item</th>
                                    <th>Qty</th>
                                </tr>
                            </MDBTableHead> : " "}
                            <MDBTableBody>
                                {extraList}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBModalBody >
                    <iframe title="printer" id="ifmcontentstoprint" style={{
                        height: '0px',
                        width: '0px',
                        position: 'absolute'
                    }}></iframe>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={() => this.props.toggle(this.props.state.node)}>Close</MDBBtn>
                        <MDBBtn color="primary" onClick={this.handlePrintAction}> Print</MDBBtn>
                    </MDBModalFooter>
                </MDBModal >
            </MDBContainer >
        );
    }
}

export default ServiceModal;